<template>
  <div>
    <div class="title flex-between">
      <div>{{ $t("titleI18n.Withdrawal") }}</div>
      <div class="flex-row-center">
        <el-image :src="imgSrc[0]" style="width: 18px; height: 18px; margin: 0 5px;"></el-image>
        <el-link type="primary" :underline="false">{{ $t("titleI18n.WithdrawalInstructions") }}</el-link>
      </div>
      <div>&nbsp;</div>
    </div>
    <el-col :span="24">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="195px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('formTitleI18n.WithdrawalAmount')" prop="moneyFormat">
          <el-input
            v-model="ruleForm.moneyFormat"
            @change="formatAmount"
            autocomplete="off"
            style="width: 300px;"
          ></el-input>
          {{ $t("unitI18n.Dollars") }}
          <div>{{ $t('columnI18n.DescWithdrawal') }}{{ totalAmount }} {{ $t('dollar') }}</div>
        </el-form-item>
        <el-form-item :label="$t('formTitleI18n.ReceivingBank')" prop="receivingBank">
          <label>{{ ruleForm.receivingBank }}</label>
        </el-form-item>
        <el-form-item :label="$t('companyCreate.bankNumber')" prop="bankAccountNumber">
          <label>{{ ruleForm.bankAccountNumber }}</label>
        </el-form-item>
        <el-form-item :label="$t('companyCreate.bankAccount')" prop="accountHolderName">
          <label>{{ ruleForm.accountHolderName }}</label>
        </el-form-item>
        <el-form-item :label="$t('companyCreate.swiftCode')" prop="swiftCode">
          <label>{{ ruleForm.swiftCode }}</label>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="24" class="flex-row-center">
      <el-button
        class="btn-black"
        @click="submitWithdrawal"
        :disabled="submitForm"
      >{{ $t("btnI18n.Submit") }}</el-button>
      <el-button
        class="btn-black"
        @click="$router.go(-1);"
        :disabled="submitForm"
      >{{ $t("btnI18n.Cancel") }}</el-button>
    </el-col>
  </div>
</template>

<script>
import filter from "@/filters";

export default {
  name: "withdrawal",
  data() {
    return {
      imgSrc: [require("@/static/img/tips-withdrawal.png")],
      submitForm: false,
      totalAmount: 0,
      ruleForm: {
        moneyFormat: 0,
        tokenVal: 0,
        bankAccountNumber: "",
        receivingBank: "",
        accountHolderName: "",
        swiftCode: "",
        txId: "",
      },
      backUrl: "",
      requestUrl: "",
      userUrl: "",
      address: "",
      rules: {
        accountHolderName: [{ required: true, message: this.$t('userCannotEmpty'), trigger: 'blur' }],
        bankAccountNumber: [{ required: true, message: this.$t('accountCannotEmpty'), trigger: 'blur' }],
        receivingBank: [{ required: true, message: this.$t('bankCannotEmpty'), trigger: 'blur' }],
        swiftCode: [{ required: true, message: this.$t('companyCreate.swiftCodeCannotEmpty'), trigger: 'blur' }],
        moneyFormat: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
        tokenVal: [{ required: true, message: this.$t('completeInput'), trigger: 'blur' }],
      },
    };
  },
  mounted() {
    this.backUrl = this.$route.path.includes("supply") ? '/supply-tr' : '/invest-tr';
    this.getUserInfo();
    this.getBalance();
  },
  methods: {
    formatAmount() {
      /^\d+$/.test(this.ruleForm.moneyFormat) ? this.ruleForm.moneyFormat > 0 ? this.ruleForm.moneyFormat : this.ruleForm.moneyFormat = 1 : this.ruleForm.moneyFormat = 1;
      this.ruleForm.moneyFormat = filter.numberFormat(this.ruleForm.moneyFormat, 0);
      this.ruleForm.tokenVal = filter.reNumber(this.ruleForm.moneyFormat);
    },
    getBalance() {
      this.$axios.get('/v1/user/balance').then(res => {
        if (res.code === 0 || res.code === '0') {
          this.totalAmount = res.data.banlance ? Number(res.data.banlance).toFixed(2) : '0.00';
        } else {
          this.$message({ type: 'error', message: res.message });
        }
      });
    },
    getUserInfo() {
      this.$axios.get('/v1/user/bank-card-info').then(res => {
        if (res.code === 0 || res.code === '0') {
          this.ruleForm.bankAccountNumber = res.data.bankAccountNumber;
          this.ruleForm.receivingBank = res.data.receivingBank;
          this.ruleForm.accountHolderName = res.data.accountHolderName;
          this.ruleForm.swiftCode = res.data.swiftCode;
        } else {
          this.$message({ type: 'error', message: res.message });
        }
      });
    },
    submitWithdrawal() {
      if (!this.ruleForm.moneyFormat || this.ruleForm.moneyFormat === "" || Number(this.ruleForm.moneyFormat) <= 0) {
        this.$message.error(this.$t('validateFormTips.requireAmount'));
        return false;
      }
      this.submitForm = true;
      this.$axios.post("/v1/user/withdraw", { tokenVal: (this.ruleForm.tokenVal).toString() }).then(result => {
        if (result.code === 0 || result.code === '0') {
          this.submitForm = false;
          this.$router.push({ path: this.backUrl });
        } else {
          this.submitForm = false;
          this.$message({ type: 'error', message: result.message });
        }
      }).catch((error) => {
        console.log("submit form error", error);
        this.submitForm = false;
      });
    }
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      console.log(this.language);
    }
  }
};
</script>

<style scoped>
</style>
